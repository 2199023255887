<template>
    <div class="c-panel" :class="{'c-panel--form': is_form, 'u-margin-bottom-small': !is_last}">
        <component v-if="title" :is="is_form ? 'h2' : 'span'" class="u-margin-bottom-small" :class="is_form && 'c-heading--medium u-no-margin-top'">
            {{ title }}
        </component>
        <div :class="{'u-margin-top-small': title}">
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title : String,
            is_form : Boolean,
            is_last : Boolean
        }
    }
</script>